import React, { useState, useEffect } from "react";
import {
  ABOUT,
  PARTNERS,
  SPONSORS,
  PLATFORM,
  RESPONSIBILITY,
} from "../helpers/constants";
// Раскоментировать при необходимости вернуть на сайт блок Спосоры
// import { isLangEn, isLangEl } from "../helpers/localization";
import ReactMarkdown from "react-markdown";

import styles from "./TextBlock.module.scss";

const TextBlock = ({ content }) => {
  const [isActive, setActive] = useState(false);
  const [sectionStyle, setSectionStyle] = useState(`${styles.text}`);

  const hasIntoText = typeof content.introText === "string";
  const hasDescription = content.description;

  const hasTitle =
    content.title !== "" &&
    content.id !== PARTNERS &&
    content.id !== SPONSORS;

  const hasTitlePartners = content.id === PARTNERS;

  // Раскоментировать при необходимости вернуть на сайт блок Спосоры
  // const isSponsorsBlock = content.id === SPONSORS;

  const getSectionStyle = () => {
    switch (content.id) {
      case ABOUT:
        return styles.aboutImage;
      case PLATFORM:
        return styles.platformImage;
      case RESPONSIBILITY:
        return styles.responsImage;
      case PARTNERS:
        return styles.partnersImage;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (content.id === PARTNERS) {
      setSectionStyle(`${styles.partnersText}`);
    }
    if (content.id === SPONSORS) {
      setSectionStyle(`${styles.textEmpty}`);
    }
  }, [content.id]);

  const onToggle = () => {
    setActive(!isActive);
    const elem = document.getElementById(content.id + "_text");
    const displaySetting = elem.style.display;
    if (
      content.id !== SPONSORS &&
      content.id !== PARTNERS
    ) {
      if (displaySetting !== "block") {
        elem.style.display = "block";
        elem.style.opacity = 0.9;
      } else {
        elem.style.display = "none";
      }
    }
  };

  return (
    <div className={getSectionStyle()} id={content.id}>
      {hasTitle && <h2>{content.title}</h2>}
      {hasTitlePartners && (
        <div className={styles.top}>
          <div className={styles.wrap}>
            <h2>{content.title}</h2>
          </div>
        </div>
      )}
      {hasIntoText && (
        <div className={styles.introText}>
          {content.introText}
          {hasDescription && (
            <div
              onClick={onToggle}
              aria-hidden="true"
              className={
                isActive ? `${styles.arrowsBefore}` : `${styles.arrowsAfter}`
              }
            />
          )}
        </div>
      )}
      {hasDescription && (
        <div className={sectionStyle} id={content.id + "_text"}>
          <ReactMarkdown>{content.description}</ReactMarkdown>
        </div>
      )}
      {/* Скрыла блок Спонсоры, раскоментировать при необходимости вернуть на сайт */}
      {/* {isSponsorsBlock && (
        <div className={styles.sponsorsBlock}>
          {isLangEn() && (
            <div className={styles.sponsorsItemEn}>
              <div className={styles.sponsorsImgEn}>
                <ReactMarkdown>{content.column}</ReactMarkdown>
              </div>
            </div>
          )}
          {isLangEl() && (
            <>
              <div className={styles.sponsorsItem}>
                <div className={styles.sponsorsTitle}>{content.title1}</div>
                <div className={styles.sponsorsImg}>
                  <ReactMarkdown>{content.column1}</ReactMarkdown>
                </div>
              </div>
              <div className={styles.sponsorsItem}>
                <div className={styles.sponsorsTitle}>{content.title2}</div>
                <div className={styles.sponsorsImg}>
                  <ReactMarkdown>{content.column2}</ReactMarkdown>
                </div>
              </div>
              <div className={styles.sponsorsItem}>
                <div className={styles.sponsorsTitle}>{content.title3}</div>
                <div className={styles.sponsorsImg}>
                  <ReactMarkdown>{content.column3}</ReactMarkdown>
                </div>
              </div>
            </>
          )}
        </div>
      )} */}
    </div>
  );
};

export default TextBlock;
