import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

import styles from "./Products.module.scss";

const Products = ({ title, gridItems }) => {
  return (
    <div className={styles.block} id="products">
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.products}>
        {gridItems.map((item) => (
          <div key={item.text} className={styles.productItem}>
            <section className={styles.section}>
              <div className={styles.image}>
                <PreviewCompatibleImage imageInfo={item} />
              </div>
              <h3 className={styles.titleH3}>{item.title}</h3>
              <p className={styles.text}>{item.text}</p>
            </section>
          </div>
        ))}
      </div>
    </div>
  );
};

Products.propTypes = {
  title: PropTypes.string,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default Products;
